/**
 * Buttons Component
 *
 * NOTE: Also see elements/_buttons.scss
 */
$grid-gutter: 30px;

$breakpoints: (
    small: 0,
    medium: 640px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
);

.buttons-component {
    padding: 0 rem-calc( $grid-gutter / 2 );

    // @include breakpoint(medium) {
        
    // }

    @media screen and ( min-width: map-get( $breakpoints, 'medium' ) ) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        padding: 0 rem-calc( $grid-gutter / 2 );
    }

    .button {
        @media screen and ( max-width: map-get( $breakpoints, 'medium' ) ) {
            display: block !important;
        }

        @media screen and ( min-width: map-get( $breakpoints, 'medium' ) ) {
            margin-right: rem-calc(10);

            &:last-child {
                margin-right: 0;
            }
        }

        // Icon
        &__icon {
            height: 1em;
            width: auto;
        }

        // Custom Icon
        &--custom-icon {
            .button__icon {
            }
        }

        // Font Awesome Icon
        &--fontawesome-icon {
            .button__icon {
            }
        }

        // Icon Alignment
        &--icon {
            &-left .button__icon {
                padding: rem-calc(0 10 0 0);
            }

            &-right .button__icon {
                padding: rem-calc(0 0 0 10);
            }
        }

        // Layout Helpers
        // NOTE: uncomment @include foundation-prototype-spacing; in _foundation.scss
    }
}
